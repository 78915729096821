import "./style.scss";

import PropTypes from "prop-types";
import { createClassName } from "../../helpers";

const Testimonial = (props) => {
  const cn = createClassName("testimonial");
  const blockquote = [
    cn({ quote: true }),
    props.isItalicStyle && cn({ quote: "italic" }),
  ].join(" ");

  return props.item && (
  <figure className={[cn("wrap"), props.className].join(" ")}>
    <blockquote className={blockquote}>{props.item}</blockquote>
    <figcaption className={cn({ author: `position-${props.position}` })}>
      <span className={cn({ author: "name" })}>{props.author}</span>
      <span className={cn({ author: "skill" })} dangerouslySetInnerHTML={{ __html: props.skill }} />
    </figcaption>
  </figure>
    );
};

Testimonial.defaultProps = {
  position: "column",
  item: "",
  skill: "",
  author: "",
  isItalicStyle: false,
  className: "",
};

Testimonial.propTypes = {
  className: PropTypes.string,
  isItalicStyle: PropTypes.bool,
  position: PropTypes.string,
  item: PropTypes.string,
  skill: PropTypes.string,
  author: PropTypes.string,
};

export default Testimonial;
