import "./style.scss";

import PropTypes from "prop-types";
import Container from "../Container";
import { createClassName } from "../../helpers";

const Benefits = (props) => {
  const cn = createClassName("benefits");

  const list = props.list.map((item) => (
    <li className={`${props.itemClassName} ${cn("item")}`} key={item.title}>
      <div className={`${props.blockClassName} ${cn("item-wrap")}`}>
        <div className={cn("item-block")}>
          <span className={cn("item-title")}>{item.title}</span>
        </div>
        <p className={cn("item-description")}>{item.description}</p>
      </div>
    </li>
  ));

  return (
    <section aria-label={props.label} className={cn()}>
      <Container className={`${cn("wrap")} ${props.className}`}>
        <ul className={`${cn("list")} ${props.listClassName}`}>{list}</ul>
      </Container>
    </section>
  );
};

Benefits.defaultProps = {
  className: "",
  listClassName: "",
  itemClassName: "",
  blockClassName: "",
};

Benefits.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  blockClassName: PropTypes.string,
};

export default Benefits;
