import { Children } from "react";
import PropTypes from "prop-types";

import { createClassName } from "../../../helpers";
import "./style.scss";

const List = (props) => {
  const cn = createClassName("mark-list");
  const childrenList = props.children.length - 1;

  return (
    <>
      {props.title && <p className={props.titleClassName}>{props.title}</p>}
      <ul
        className={`${cn({
        vertical: props.isVertical,
        "is-mark": true,
        "with-mark": props.withMark,
      })} ${props.listClassName}`}
      >
        {Children.map(props.children, (item, index) => {
        const isLast = index === childrenList;
        return (
          <li
            id={props.id}
            key={index}
            className={`
                ${cn("item", {
                  "is-mark": true,
                  vertical: props.isVertical,
                  last: isLast,
                  "with-mark": props.withMark,
                  "with-check": props.withCheck,
                })} 
                ${props.itemClassName}
                ${
                  props.itemClassName && isLast
                    ? `${props.itemClassName}_last`
                    : ""
                }
              `}
          >
            {item}
          </li>
        );
      })}
      </ul>
    </>
);
};

List.defaultProps = {
  isVertical: false,
  withMark: false,
  withCheck: false,
  listClassName: "",
  itemClassName: "",
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  isVertical: PropTypes.bool,
  withMark: PropTypes.bool,
  withCheck: PropTypes.bool,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
};

export default List;
