import "./style.scss";

import AccentContainer from "../../Container/_accent";
import Container from "../../Container/";
import Heading from "../../Heading";
import { useImageRendering } from "../../Image";
import LinkOnResource from "../../Links/LinkOnResource"
import PropTypes from "prop-types";
import { createClassName } from "../../../helpers";
import loadable from '@loadable/component'
import { LocaleContext } from "../../../layouts/LocaleContext";
import { useContext, useEffect, useState } from "react"

const cn = createClassName("main-banner");

const mobileImg = (val) => val.find(img => img.mobileImage)

const Illustrates = props => {
  const Image = useImageRendering(props);
  const { mobileImage, alt } = mobileImg(props.mobileImage)
  return (
    <div
      className={cn("illustrates-item", {
        group: "main",
        mobile: true
      })}
    >
      <Image
        src={mobileImage}
        alt={alt}
      />
    </div>
  );
};

const MainBanner = props => {
  const Image = useImageRendering(props);
  const [ParallaxBanner, setParallaxBanner] = useState(null);
  const { isMobile } = useContext(LocaleContext);

  useEffect(() => {
    !isMobile && setParallaxBanner(loadable(() => import('../../Parallax')));
  }, [isMobile]);

  return (
    <AccentContainer>
      <div
        role="banner"
        className={cn("banner", { wrap: true })}
        id="main-banner-container"
      >
        <Container className={cn("banner", { container: true })}>
          <div className={cn("title")}>
            <Heading
              level={1}
              color="white"
              align="center"
              weight="medium"
              className={cn("title", { mobile: true })}
            >
              {props.heading}
            </Heading>
            <Heading
              level={4}
              color="white"
              className={cn("sub-title")}
              weight="light"
              align="center"
            >
              {props.bannerSubTitle}
            </Heading>
          </div>
          {isMobile && (
            <Illustrates
              mobileImage={props.imageBanners}
              alt={props.mobileImageAlt}
              imageRenderer={Image}
            />
          )}
          <LinkOnResource
            href="/download-free-trial/"
            isVolume
            color="white"
            className={cn({ link: "trial" })}
            isRel={false}
            withoutLocale={false}
          >
            {props.buttonText}
          </LinkOnResource>
          {(!isMobile && ParallaxBanner) && (<ParallaxBanner
            className={cn("illustrates")}
            imageBanners={props.imageBanners}
            imageRenderer={Image}
          />)}
        </Container>
      </div>
    </AccentContainer>
  );
};

MainBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  bannerSubTitle: PropTypes.string.isRequired,
  buttonHandler: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  imageBanners: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ),
};

export default MainBanner;
