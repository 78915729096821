import "./style.scss";

import Heading from "../Heading";
import HeadingWithAccentWords from "../Heading/_withAccentWords";
import LinkWithArrow from "../Links/LinkOnPage/_withArrow"
import { LocaleContext } from "../../layouts/LocaleContext";
import MarkList from "../List/MarkList";
import Paragraph from "../Paragraph";
import PropTypes from "prop-types";
import { useContext } from "react";
import { createClassName } from "../../helpers";
import loadable from '@loadable/component'
import { useVideoRendering } from "../Video";

// import FeaturesImage from "./_image";
// import { useImageRendering } from "../Image";
// import FeatureVideo from './FeatureVideo'

const FeaturesImage = loadable(() => import('./_image'));

const cn = createClassName("features");

const FeaturesReview = props => {
  const { isMobile } = useContext(LocaleContext);
  const itemRefList = [];
  // const videoRefListDesktop = [];
  // const FeaturesImage = useImageRendering(props);
  const Video = useVideoRendering(props);

  const ItemVideo = props => (
    <div
      className={`${cn("item", {
        image: true,
        desktop: true,
      })} ${props.videoClassName}`}
    >
      {props.video && props.video.length ? (
        // <FeatureVideo
        //   ref={ref => videoRefListDesktop.push(ref)}
        //   sources={props.video}
        // />
        <Video sources={props.video} lazy autoPlay isLoop />
      ) : (
          <FeaturesImage src={props.img.src} alt={props.img.alt} {...props} />
        )}
    </div>
  );

  // useEffect(() => {
  //   const startPlayPosition = !mobile ? (window.innerHeight * 2) / 3 : undefined;

  //   const detectPosition = (item) => item.getBoundingClientRect().top;

  //   const startPlayVideo = (item, index) => {
  //     if (detectPosition(item) < startPlayPosition) {
  //       if (videoRefListDesktop[index - 1] && !videoRefListDesktop[index - 1].paused) {
  //         videoRefListDesktop[index - 1].play().then(() => videoRefListDesktop[index - 1].pause())
  //       }
  //       videoRefListDesktop[index].play();
  //     } else videoRefListDesktop[index].pause();
  //   };

  //   !mobile && itemRefList.map((item, index) => {
  //     document.addEventListener("scroll", () => startPlayVideo(item, index));
  //     return document.removeEventListener("scroll", () =>
  //       startPlayVideo(item, index)
  //     );
  //   });

  //   //return () => videoRefListDesktop.forEach(video => videoRefListDesktop[video].pause())
  // }, [mobile]);

  const featureReview = props.featuresReviewList.map((item, mapIndex) => {
    const position = mapIndex % 2 === 0;
    return (
      <li key={item.heading} className={cn("item", { wrap: true })}>
        <div
          ref={ref => itemRefList.push(ref)}
          className={cn("item", {
            wrap: "content",
            position: position ? "left" : "right",
          })}
        >
          {!isMobile && (
            <ItemVideo
              {...item}
              videoClassName={cn("item", {
                video: "desktop",
              })}
            />
          )}
          <div className={cn("item", { description: true })}>
            <div className={cn("item", { title: true })}>
              <HeadingWithAccentWords
                level={2}
                fontLevel={3}
                accentTitle={item.heading}
                accentColor="primary-main"
                subTitle={item.afterHeading}
                beforetitle={item.beforeHeading}
                subColor="normal"
              />
              <Heading level={3} fontLevel={4} weight="normal">
                {item.subHeading}
              </Heading>
            </div>
            {isMobile && (
              <div className={cn("item", { image: "mobile" })}>
                <FeaturesImage
                  src={item.img.src}
                  alt={item.img.alt}
                  className={cn("item", { img: "mobile" })}
                  {...props}
                />
              </div>
            )}

            <Paragraph bottomIndent>{item.description}</Paragraph>
            <Paragraph>{item.list.title}</Paragraph>

            <MarkList
              isVertical
              withMark
              listClassName={cn("item", { description: "list" })}
              itemClassName={cn("item", { description: "item" })}
            >
              {item.list.content}
            </MarkList>

            <LinkWithArrow
              href={`/${item.link}/`}
              className={cn("link")}
              color="primary-main"
            >
              {item.textLink}
            </LinkWithArrow>
          </div>
        </div>
      </li>
    );
  });

  return <ul className={cn("list")}>{featureReview}</ul>;
};

FeaturesReview.propTypes = {
  featuresReviewList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FeaturesReview;
