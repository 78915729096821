import { SITE_URL } from "../../../../config/i18n";

export const data = {
  "/": `
    <script defer type="application/ld+json">
    [{
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "XLTools Add-in",
        "image": "${SITE_URL}/static/fa1d6577283a47545b9b52ddf180f6ff/xltools-add-in-logo.svg",
      "screenshot": "${SITE_URL}/static/7cd9b624690124ce4ac5f4bd4c58c557/33964/xltools-add-in-for-excel-ribbon.png",
        "applicationCategory": [
      "Productivity software",
      "Office Add-ins"
      ],
      "url": "${SITE_URL}/",
      "downloadUrl": "${SITE_URL}/download-free-trial/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "For MS Excel 2021, 2019, 2016, 2013, 2010, desktop Office 365",
        "description": "XLTools is a powerful Excel add-in designed to help business users simplify their daily Excel workload. Includes tools to clean data, track changes, automate tasks, and more.",
      "publisher": "WaveAccess Nordics ApS",
        "offers": {
         "@type": "AggregateOffer",
           "offerCount": "4",
           "lowPrice": "14.95",
           "highPrice": "199.95",
           "priceCurrency": "USD"
         },
          "sameAs" : [
        "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    },
    {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "XLTools Add-in",
      "parentOrganization": "WaveAccess Nordics ApS",
      "url": "${SITE_URL}/",
      "logo": "${SITE_URL}/static/fa1d6577283a47545b9b52ddf180f6ff/xltools-add-in-logo.svg",
        "contactPoint" : [
            {
            "@type" : "ContactPoint",
            "contactType" : "Customer support",
            "email": "support@xltools.net",
            "url": "${SITE_URL}/support/"
            }
        ]
    }]
    </script>
    `,
  "/excel-popup-calendar-toolkit/": `<script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Popup Calendar Toolkit for Excel",
        "applicationSuite": "XLTools Add-in",
        "image": "${SITE_URL}/static/fbeb39ee5e77cb20ca31243ee3bba33a/fa4c2/popup-calendar-insert-date-time-in-excel.png",
      "screenshot": "${SITE_URL}/static/7cd9b624690124ce4ac5f4bd4c58c557/33964/xltools-add-in-for-excel-ribbon.png",
        "applicationCategory": [
      "Productivity software",
      "Office Add-ins"
      ],
        "url": "${SITE_URL}/excel-popup-calendar-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "For MS Excel 2021, 2019, 2016, 2013, 2010, desktop Office 365",
        "description": "A date picker for Excel spreadsheets - insert and edit dates and time in cells easily.",
      "publisher": "WaveAccess Nordics ApS",
        "offers": {
          "@type": "Offer",
          "price": "14.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>`,

  "/excel-data-preparation-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Data Prep Toolkit for Excel",
        "applicationSuite": "XLTools Add-in",
        "image": "${SITE_URL}/static/4bfd6065112c9994210e8e231ec1927c/fa4c2/data-prep-clean-cells.png",
      "screenshot": "${SITE_URL}/static/7cd9b624690124ce4ac5f4bd4c58c557/33964/xltools-add-in-for-excel-ribbon.png",
        "applicationCategory": [
      "Productivity software",
      "Office Add-ins"
      ],
        "url": "${SITE_URL}/excel-data-preparation-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "For MS Excel 2021, 2019, 2016, 2013, 2010, desktop Office 365",
        "description": "A set of essential tools to clean, transform and prepare Excel spreadsheets for analysis.",
      "publisher": "WaveAccess Nordics ApS",
        "offers": {
          "@type": "Offer",
          "price": "99.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,
  "/excel-automation-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Automation Toolkit for Excel",
        "applicationSuite": "XLTools Add-in",
        "image": "${SITE_URL}/static/a20df87badecfcbb74157dd800aadf67/fa4c2/automation-write-commands-in-excel-table.png",
      "screenshot": "${SITE_URL}/static/7cd9b624690124ce4ac5f4bd4c58c557/33964/xltools-add-in-for-excel-ribbon.png",
        "applicationCategory": [
      "Productivity software",
      "Office Add-ins"
      ],
        "url": "${SITE_URL}/excel-automation-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "For MS Excel 2021, 2019, 2016, 2013, 2010, desktop Office 365",
        "description": "A set of tools to automate repetitive tasks in Excel without VBA macros.",
      "publisher": "WaveAccess Nordics ApS",
        "offers": {
          "@type": "Offer",
          "price": "199.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,
  "/excel-version-control-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Version Control Toolkit for Excel",
        "applicationSuite": "XLTools Add-in",
        "image": "${SITE_URL}/static/db66a413ee7f58be5ee86867eccddac2/fa4c2/version-control-compare-worksheet-versions.png",
      "screenshot": "${SITE_URL}/static/7cd9b624690124ce4ac5f4bd4c58c557/33964/xltools-add-in-for-excel-ribbon.png",
        "applicationCategory": [
      "Productivity software",
      "Office Add-ins"
      ],
        "url": "${SITE_URL}/excel-version-control-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "For MS Excel 2021, 2019, 2016, 2013, 2010, desktop Office 365",
        "description": "A set of tools to track changes to Excel spreadsheets and VBA projects.",
      "publisher": "WaveAccess Nordics ApS",
        "offers": {
          "@type": "Offer",
          "price": "149.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,
};
